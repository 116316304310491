/* 1 Typography
----------------------------------------------------------------------------- */
/*Fonts
---------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.navbar .navbar-nav {
	font-family: @font-family-raleway;
}
body {
	font-family: @font-family-base;
}
.logo-font {
	font-family: @font-family-pacifico;
}
/*Typography
---------------------------------- */
html {
	height: 100%;
}
body {
	font-size: @font-size-base;
	line-height: @line-height-base;
	color: @gray;
	background-color: @body-bg;
	font-weight: 300;
}
body.canvas-sliding,
body.canvas-slid {
	overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: @gray-dark;
	line-height: @headings-line-height;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: @gray-dark;
}
.dark {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #ffffff;
	}
	h1 a,
	h2 a,
	h3 a,
	h4 a,
	h5 a,
	h6 a {
		color: #ffffff;
	}
}
.dark .footer {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: @gray-light;
	}
	h1 a,
	h2 a,
	h3 a,
	h4 a,
	h5 a,
	h6 a {
		color: @gray-light;
	}
}
h1 {
	font-size: @font-size-h1;
	font-weight: 700;
	text-transform: uppercase;
}
h1.logo-font {
	text-transform: none;
	font-weight: 300;
	font-size: 50px;
}
h1.large {
	font-size: @font-size-h1-large;
	font-weight: 300;
	letter-spacing: 0.1em;
}
h2 {
	font-size: @font-size-h2;
	margin-bottom: 15px;
	text-transform: uppercase;
}
h2.logo-font {
	text-transform: none;
}
h3 {
	font-size: @font-size-h3;
}
h4 {
	font-size: @font-size-h4;
}
h5 {
	font-size: @font-size-h5;
	text-transform: uppercase;
	font-weight: 700;
}
h6 {
	font-size: @font-size-h6;
	font-weight: 700;
}
/* Small devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
	.jumbotron h1 {
		font-size: 130px;
	}
}
/* Small devices (tablets, phones less than 767px) */
@media screen and (max-width: 767px) {
	.jumbotron h1 {
		font-size: 60px;
	}
}
.page-title {
	margin-top: 0;
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
	.title {
		margin-top: 0;
	}
}
.sidebar .title {
	margin-top: 3px;
}
p {
	margin-bottom: 15px;
}
p.large {
	font-size: 18px;
	line-height: 1.4;
	margin-bottom: 30px;
}
a {
	color: @base-color;
	.transition(all 0.2s ease-in-out);
}
a:hover {
	color: @base-color-hover;
}
a:focus,
a:active {
	outline: none;
	color: @base-color-hover;
}
.link-dark {
	color: @gray-dark;
	text-decoration: none!important;
}
.link-dark:hover {
	color: @gray-dark;
	text-decoration: underline!important;
}
.dark .footer {
	.link-dark {
		color: @gray;
		text-decoration: none!important;
	}
	.link-dark:hover {
		color: @gray;
		text-decoration: underline!important;
	}
}
.link-light {
	color: @white;
	text-decoration: none!important;
}
.link-light:hover {
	color: @white;
	text-decoration: underline!important;
}
.no-trans * {
	-webkit-transition: none !important;
	   -moz-transition: none !important;
		-ms-transition: none !important;
		 -o-transition: none !important;
			transition: none !important;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
}
blockquote {
	border-left: none;
	display: inline-block;
	margin: 20px auto 20px;
	font-size: 16px;
	position: relative;
	padding: 10px 25px;
}
blockquote:after {
	content: "“";
	width: 25px;
	height: 25px;
	line-height: 36px;
	font-size: 36px;
	font-family: @font-family-pt-serif;
	position: absolute;
	top: 12px;
	left: 0px;
	color: @gray-lighter;
}
.blockquote:before {
	font-family: @font-family-pt-serif;
	content: "“";
	font-size: 40px;
}
blockquote.inline {
	padding: 0;
}
blockquote.inline p {
	width: 60%;
	display: inline-block;
	margin: 0;
}
blockquote.inline footer {
	width: 37%;
	display: inline-block;
	padding-left: 5px;
}
blockquote.inline:after {
	top: 0;
}
mark,
.mark {
	background-color: @base-color;
	color: #ffffff;
}
pre {
	padding: 20px;
	.border-radius(0px);
}
.text-muted {
	color: #999999;
}
.text-default {
	color: @base-color;
}
.text-white {
	color: #ffffff!important;
}
.well {
	.border-radius(0px);
	background-color: #f3f3f3;
	.box-shadow(inset 0 1px 2px rgba(0, 0, 0, .05));
}
.dark hr {
	border-color: @gray-dark;
}
/* Lists
---------------------------------- */
ul {
	list-style: square;
}
.list-icons,
.list {
	list-style: none;
	padding: 0;
}
.list-icons li,
.list li {
	padding: 5px 0;
}
.list-icons li i {
	min-width: 25px;
	text-align: center;
}
.list-inline {
	margin-top: 9px;
	margin-bottom: 8px;
}

.header-top .list-inline {
	display: inline-block;
}
/* Medium desktop devices and tablets */
@media (min-width: 992px) and (max-width: 1199px) { 
	.header-top .list-inline > li { 
		padding: 0; 
	}
}

/* Tables
---------------------------------- */
.table:not(.table-bordered) {
	border-bottom: 2px solid #f3f3f3;
}
.table > thead > tr > th {
	vertical-align: bottom;
	border-bottom: 1px solid #eaeaea;
	background-color: #eaeaea;
	color: @gray-dark;
	font-weight: 400;
}
.table.table-colored {
	border-bottom-color: @base-color;
}
.table-colored > thead > tr > th {
	border-color: @base-color;
	background-color: @base-color;
	color: #fff;
}
tbody {
	background-color: #ffffff;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 8px 15px;
	border-top: 1px solid #f3f3f3;
}
.table-striped tbody {
	background-color: #ffffff;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
	background-color: #f5f5f5;
}
.table-striped.table > tbody > tr > td {
	border-color: #e8e8e8;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
	border-color: #f3f3f3;
}
.dl-horizontal dd {
	margin-bottom: 10px;
}
.table-hover > tbody > tr:hover {
	background-color: rgba(0, 0, 0, 0.04)
}
.dark .table {
	&:not(.table-bordered) {
		border-bottom: 2px solid rgba(255, 255, 255, 0.07);
	}
	> tbody {
		background-color: transparent;
		> tr > td {
			border-top: 1px solid rgba(255, 255, 255, 0.07);
		}
	}
	> thead > tr > th {
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
		background-color: rgba(0, 0, 0, 0.15);
		color: #f1f1f1;
		font-weight: 400;
	}
}
/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
	.dl-horizontal dd {
		margin-left: 150px;
	}
	.dl-horizontal dt {
		max-width: 120px;
	}
}
