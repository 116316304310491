/* 10 Full Page
----------------------------------------------------------------------------- */
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: rgba(9, 175, 223, 0.8);
}
#fp-nav ul li .fp-tooltip {
	background-color: #fff;
	color: #454545;
	padding: 0 15px;
	.border-radius(4px);
	top: -4px;
	overflow: visible;
}
#fp-nav ul li .fp-tooltip.right {
	right: 30px;
}
#fp-nav ul li .fp-tooltip:after {
	content: "\f0da";
	font-family: "FontAwesome";
	position: absolute;
	right: -8px;
	top: 50%;
	font-size: 30px;
	line-height: 20px;
	margin-top: -9px;
	color: #fff;
}